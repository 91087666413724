<template>
    <!-- 个人分销设置 -->
    <div class="pageContol listWrap templateList">
        <div class="framePage">
            <div class="framePage-title">
                <span>
                <em>当前位置：</em>
                <a href="javascript:;">分销管理</a>
                <i>></i>
                <a href="javascript:;" class="cur-a">个人分销设置</a>
                </span>
            </div>
            <div class="framePage-body">
                <el-tabs>
                    <el-alert
                        :closable="false"
                        title="个人分销仅支持平台精品课内容，分销比例支持手动修改。"
                        type="warning">
                    </el-alert>
                    <el-tab-pane label="个人分销">
                        <el-form label-width="100px" :model="formData" ref="ruleForm" :rules="rules" style="padding:20px;" size="small">
                            <h3 class="block-title">自有课程</h3>
                            <el-form-item label="返利百分比" prop="percentage">
                                <el-input clearable v-model="formData.percentage" @input="filter('percentage')">
                                    <template slot="append">%</template>
                                </el-input>
                            </el-form-item>
                            <h3 class="block-title">合作课程</h3>
                            <el-form-item label="返利百分比" prop="thirdPartyPercentage">
                                <el-input clearable v-model="formData.thirdPartyPercentage" @input="filter('thirdPartyPercentage')">
                                    <template slot="append">%</template>
                                </el-input>
                            </el-form-item>
                            <el-divider></el-divider>
                            <el-form-item label="提现门槛" prop="threshold">
                                <el-input clearable v-model="formData.threshold" @input="filter('threshold')">
                                    <template slot="append">元</template>
                                </el-input>
                            </el-form-item>
                            <el-divider></el-divider>
                            <el-form-item>
                                <el-button class="bgc-bv" @click="submit">保存</el-button>
                                <!-- <el-button>取消</el-button> -->
                            </el-form-item>
                        </el-form>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:"personalDistributionSetup",
        created(){
            this.echo();
        },
        data(){
            const validBFB=(rule,value,callback)=>{
                const reg=/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
                if(!reg.test(value)) return callback("请输入正确的返利百分比");
                callback();
            }
            const validThreshold=(rule,value,callback)=>{
                const reg=/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
                if(!reg.test(value)) return callback("请输入正确的提现门槛");
                callback();
            }
            return{
                // 表单数据
                formData:{
                    percentage:"",//自有课返利百分比
                    thirdPartyPercentage:"",//和做课返利百分比
                    threshold:"",//提现门槛
                },
                // 表单验证规则
                rules:{
                    percentage:[
                        {required:true,message:"请输入返利百分比",trigger:"blur"},
                        {validator:validBFB,trigger:"blur"}
                    ],
                    thirdPartyPercentage:[
                        {required:true,message:"请输入返利百分比",trigger:"blur"},
                        {validator:validBFB,trigger:"blur"}
                    ],
                    threshold:[
                        {required:true,message:"请输入提现门槛",trigger:"blur"},
                        {validator:validThreshold,trigger:"blur"}
                    ]
                }
            }
        },
        methods:{
            // 提交
            submit(){
                this.$refs.ruleForm.validate((valid)=>{
                    if(valid){
                        this.$post("/biz/distribute/setting/saveOrUpdate",this.formData,3000,true,2).then(()=>{
                            this.$message.success("保存成功");
                        }).catch(e=>{
                            console.error("保存出错",e);
                        });
                    }
                });
            },
            // 回显
            echo(){
                const {formData}=this;
                this.$post("/biz/distribute/setting/getInfo",this.formData,3000,true,2).then(res=>{
                    // 回显数据
                    Object.keys(formData).forEach(key=>{
                        formData[key]=res.data[key];
                    });
                }).catch(e=>{
                    console.error("回显出错",e);
                });
            },
            // 过滤非法数据
            filter(key){
                const {formData}=this;
                formData[key]=formData[key].replace(/[^(\.|\d)]/ig,"");
            }
        }
    }   
</script>

<style lang="less" scoped>
    .templateList{
        .framePage{
            .el-form{
                .el-input{
                    width: 14.5rem;
                }
            }
            .block-title{
                padding-left: 10px;
                margin-bottom: 20px;
                position: relative;
                &::after{
                    content: "";
                    display: block;
                    width: 8px;
                    height: 16px;
                    background-color: #5260CF;
                    position: absolute;
                    left: 0;
                    top: 4px;
                }
            }
        }
    }
</style>